import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { InView } from 'react-intersection-observer';

const MobileSlide = () => {
  return (
    <>
      <div id="mobile-slide" className="relative">
        <StaticImage
          src="../images/GettyImages-1-506125236.jpg"
          alt=""
          loading="eager"
          layout="fullWidth"
          aspectRatio={1 / 1}
          formats={['auto', 'webp', 'avif']}
          style={{ height: '360px' }}
        />
        <div className="mobile-slide-inner">
          {/* Slide 1 */}
          <InView threshold={1}>
            {({ inView, ref, entry }) => (
              <div className={`relative w-full h-full inline-block whitespace-normal align-top transition duration-1000 ease ${inView ? 'opacity-1' : 'opacity-0' } `}>
                <div className="absolute bottom-5 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-10" ref={ref}>
                  <div>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-90" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-30" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-30" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                  </div>
                </div>
                <div className="w-full h-full">
                  <div className="grid h-full items-center justify-center text-center z-10">
                    <div className="py-0 px-4">
                      <h1 className="font-bold leading-tight tracking-wide text-2xl text-white">
                        Even the strongest climbers need a guide.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </InView>
          {/* Slide 2 */}
          <InView threshold={1}>
            {({ inView, ref, entry }) => (
              <div className={`relative w-full h-full inline-block whitespace-normal align-top transition duration-1000 ease ${inView ? 'opacity-1' : 'opacity-0' } `}>
                <div className="absolute bottom-5 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-10" ref={ref}>
                  <div>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-30" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-90" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-30" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                  </div>
                </div>
                <div className="w-full h-full">
                  <div className="grid h-full items-center justify-center text-center z-10">
                    <div className="py-0 px-4">
                      <h1 className="font-bold leading-tight tracking-wide text-2xl text-white">
                        Building a business can be a rugged experience.
                      </h1>
                      <p className="leading-normal tracking-wide text-base text-loblolly">
                        Young companies face numerous challenges as they seek to grow and gain traction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </InView>
          {/* Slide 3 */}
          <InView threshold={1}>
            {({ inView, ref, entry }) => (
              <div className={`relative w-full h-full inline-block whitespace-normal align-top transition duration-1000 ease ${inView ? 'opacity-1' : 'opacity-0' } `}>
                <div className="absolute bottom-5 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-10" ref={ref}>
                  <div>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-30" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-30" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                    <svg className="inline mr-2 w-2 h-2 fill-current text-white opacity-90" aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50"/>
                    </svg>
                  </div>
                </div>
                <div className="w-full h-full">
                  <div className="grid h-full items-center justify-center text-center z-10">
                    <div className="py-0 px-4">
                      <h1 className="font-bold leading-tight tracking-wide text-2xl text-white">
                        We guide and mentor exceptional entrepreneurs
                      </h1>
                      <p className="leading-normal tracking-wide text-base text-loblolly">
                        to help them reach the summit of business success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
    </>
  )
};

MobileSlide.propTypes = {
};

export default MobileSlide;
