import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import Slider from 'react-slick';

const settings = {
  dots: true,
  infinite: false,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
  arrows: false,
  autoplaySpeed: 6000,
  lazyLoad: 'ondemand',
};

const Banner = () => (
  <StaticQuery
    query={graphql`
      {
        tablet: allFile(
          sort: {
            fields: [name],
            order: ASC
          }
          filter: {
            absolutePath: {
              regex: "/(src/images)/.*\\.jpg$/"
            },
            name: {
              regex: "/^(GettyImages)-.*-1024$/"
            }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        laptop: allFile(
          sort: {
            fields: [name],
            order: ASC
          }
          filter: {
            absolutePath: {
              regex: "/(src/images)/.*\\.jpg$/"
            },
            name: {
              regex: "/^(GettyImages)-.*-1280$/"
            }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        desktop: allFile(
          sort: {
            fields: [name],
            order: ASC
          }
          filter: {
            absolutePath: {
              regex: "/(src/images)/.*\\.jpg$/"
            },
            name: {
              regex: "/^(GettyImages)-.*-1536$/"
            }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        xlarge: allFile(
          sort: {
            fields: [name],
            order: ASC
          }
          filter: {
            absolutePath: {
              regex: "/(src/images)/.*\\.jpg$/"
            },
            name: {
              regex: "/^(GettyImages)-.*-w$/"
            }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `}
    render={data => {

      const tablet = data.tablet;
      const laptop = data.laptop;
      const desktop = data.desktop;
      const xlarge = data.xlarge;

      const slideOne = withArtDirection(getImage(xlarge.edges[0].node), [
        {
          media: "(max-width: 1024px)",
          image: getImage(tablet.edges[0].node),
        },
        {
          media: "(max-width: 1280px)",
          image: getImage(laptop.edges[0].node),
        },
        {
          media: "(max-width: 1536px)",
          image: getImage(desktop.edges[0].node),
        },
      ]);

      const slideTwo = withArtDirection(getImage(xlarge.edges[1].node), [
        {
          media: "(max-width: 1024px)",
          image: getImage(tablet.edges[1].node),
        },
        {
          media: "(max-width: 1280px)",
          image: getImage(laptop.edges[1].node),
        },
        {
          media: "(max-width: 1536px)",
          image: getImage(desktop.edges[1].node),
        },
      ]);

      const slideThree = withArtDirection(getImage(xlarge.edges[2].node), [
        {
          media: "(max-width: 1024px)",
          image: getImage(tablet.edges[2].node),
        },
        {
          media: "(max-width: 1280px)",
          image: getImage(laptop.edges[2].node),
        },
        {
          media: "(max-width: 1536px)",
          image: getImage(desktop.edges[2].node),
        },
      ]);

      return (
        <Slider {...settings}>
          <article
            key="slide-1"
          >
            <div>
              <div>
                <GatsbyImage
                  image={slideOne}
                  alt=""
                  placeholder="blurred"
                  objectFit="cover"
                  objectPosition="top center"
                />
              </div>
              <div>
                <div>
                  <h1 className="font-bold">Even the strongest climbers need a guide.</h1>
                </div>
              </div>
            </div>
          </article>
          <article
            key="slide-2"
          >
            <div>
              <div>
                <GatsbyImage
                  image={slideTwo}
                  alt=""
                  placeholder="blurred"
                  objectFit="cover"
                  objectPosition="top center"
                />
              </div>
              <div>
                <div>
                  <h1 className="font-bold">Building a business can be a rugged experience.</h1>
                  <p>Young companies face numerous challenges as they seek to grow and gain traction.</p>
                </div>
              </div>
            </div>
          </article>
          <article
            key="slide-3"
          >
            <div>
              <div>
                <GatsbyImage
                  image={slideThree}
                  alt=""
                  placeholder="blurred"
                  objectFit="cover"
                  objectPosition="top center"
                />
              </div>
              <div>
                <div>
                  <h1 className="font-bold">We guide and mentor exceptional entrepreneurs</h1>
                  <p>to help them reach the summit of business success.</p>
                </div>
              </div>
            </div>
          </article>
        </Slider>
      )
    }}
  />
);

export default Banner;
