import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';

// Components
import Seo from '../components/Seo';
import MobileSlide from '../components/MobileSlide';
import Banner from '../components/banner';
import InvestmentCard from '../components/InvestmentCard';

// Utilities
import { shorten } from '../utils/helpers';

const IndexPage = () => {

  const [matches, setMatches] = useState(null);

  useEffect(() => {
    // We need this since window isn't available during builds.
    if (typeof window === 'undefined') return;
    // Get initial screen size.
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    // Set state.
    setMatches(mediaQuery.matches);
    // Add a listener to set state in case screen size changes.
    mediaQuery.addEventListener('change', e => setMatches( e.matches ));
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          block__mission: markdownRemark(
            frontmatter: {
              machine_name: {
                eq: "homepage-mission-intro"
              }
            }
          ) {
            html
          }
          block__ram: markdownRemark(
            frontmatter: {
              machine_name: {
                eq: "homepage-ram-intro"
              }
            }
          ) {
            html
          }
          investments: allMarkdownRemark(
            sort: {
              order: [
                ASC,
                ASC
              ],
              fields: [
                frontmatter___weight,
                frontmatter___title
              ]
            },
            filter: {
              fileAbsolutePath: {
                regex : "/\/investments\//"
              },
              frontmatter: {
                published: {
                  eq: true
                },
                featured: {
                  eq: true
                }
              },
            },
            limit: 8
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  blurb
                  title
                  logo {
                    childImageSharp {
                      gatsbyImageData(
                        width: 220,
                      )
                    }
                  }
                }
              }
            }
          }
          news: allMarkdownRemark(
            sort: {
              order: [
                DESC
              ],
              fields: [
                frontmatter___date
              ]
            },
            filter: {
              fileAbsolutePath: {
                regex : "/\/posts\//"
              },
              frontmatter: {
                published: {
                  eq: true
                }
              },
            },
            limit: 3
          ) {
            edges {
              node {
                id
                frontmatter {
                  link
                  source
                  title
                }
              }
            }
          }
          page__ram: markdownRemark(
            frontmatter: {
              path: {
                eq: "/ram-shriram"
              }
            }
          ) {
            html
          }
        }
      `}
      render={data => (
        <>
          <Seo
            title="Welcome"
          />
          <div className="slider-wrapper">
            {!matches && (<MobileSlide />)}
            {matches && (<Banner />)}
          </div>
          {/* One */}
          <section className="bg-olivine-500">
            <div className="mx-auto py-12 md:py-20 lg:py-16 px-4 sm:px-6 lg:px-8 max-w-lg lg:max-w-6xl xl:max-w-7xl">
              <div className="lg:grid lg:grid-cols-4 lg:gap-6">
                <div className="cols-span-4 lg:col-span-3">
                  {data.block__mission.html
                    && (
                      <div
                        data-aos-once="true"
                        data-aos="fade-in"
                        data-aos-offset="0"
                        data-aos-delay="0"
                        style={{ textAlign: 'left' }}
                      >
                        <div
                          id="block-mission"
                          dangerouslySetInnerHTML={{ __html: data.block__mission.html }}
                        />
                        <Link to="/mission/" aria-label="Learn more about our Mission" className="inline-flex items-center justify-center margin-auto px-5 md:px-16 py-3 md:py-4 border border-moss-green shadow-sm text-base md:text-xl font-bold no-underline text-white bg-olivine-500 hover:bg-moss-green" style={{ cursor: 'pointer'}}>
                          <FontAwesomeIcon className="mr-2 w-6 h-6 inline" icon={faInfoCircle} />
                          Learn More
                        </Link>
                      </div>
                    )
                  }
                </div>
                <div className="hidden lg:block lg:col-span-1 lg:pl-8 border-l border-moss-green">
                  <span
                    className="block"
                    style={{ maxWidth: '103px', width: '103px' }}
                    data-aos-once="true"
                    data-aos="fade-up-left"
                    data-aos-duration="800"
                    data-aos-easing="ease-out"
                  >
                    <StaticImage
                      src="../images/logo-minimize.png"
                      alt=""
                      width={103}
                      placeholder="none"
                    />
                  </span>
                </div>
              </div>
            </div>
          </section>
          {/* Two */}
          <section className="bg-white">
            <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8 max-w-lg lg:max-w-6xl xl:max-w-7xl">
              <header className="text-center pb-4" data-aos="fade-in" data-aos-once="true" data-aos-delay="0">
                <h2 className="text-2xl font-bold">News</h2>
                <p className="text-lg md:text-2xl">Latest news about our investments.</p>
              </header>
              <div className="pb-4 lg:grid lg:gap-8 lg:grid-cols-3">
                {data.news.edges && data.news.edges.map(({ node }) => (
                  <div className="text-center pb-12" key={`{inv-${node.id}`}>
                    <h3 className="text-xl font-bold mb-2"><a className="text-outer-space tracking-tight no-underline hover:underline" href={node.frontmatter.link} aria-label={node.frontmatter.title} rel="nofollow">{shorten(node.frontmatter.title, 60, ' ')}</a></h3>
                    {node.frontmatter.source
                      && (
                        <p className="font-italic">{node.frontmatter.source}</p>
                    )}
                    <a href={node.frontmatter.link} aria-label={`Read more: ${shorten(node.frontmatter.title, 60, ' ')} (${node.frontmatter.source})`} className="inline-flex items-center justify-center margin-auto px-5 py-3 border border-transparent shadow-sm text-base font-bold no-underline text-white bg-olivine-500 hover:bg-olivine-400" rel="nofollow">
                      Read More &nbsp;<FontAwesomeIcon className="ml-1 w-6 h-6 inline" icon={faExternalLinkAlt} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/* Three */}
          <section className="bg-gray-50">
            <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8 max-w-4xl lg:max-w-6xl xl:max-w-7xl">
              <header className="text-center pb-4" data-aos="fade-in" data-aos-once="true" data-aos-delay="0">
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold">Investments</h2>
              </header>
              <div className="inv-1 p-2 sm:p-0 grid xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8 lg:gap-8 xl:gap-12 mx-auto max-w-sm sm:max-w-2xl md:max-w-4xl lg:max-w-7xl">
                {data.investments.edges && data.investments.edges.map(({ node }, i) => (
                  <InvestmentCard
                    node={node}
                    index={i}
                    noLazyAboveFold={false}
                    borderColour="white"
                  />
                ))}
              </div>
              <footer className="col-span-2 text-center my-12">
                <Link to="/investments/" className="inline-flex items-center justify-center margin-auto px-5 py-3 border border-transparent shadow-sm text-base font-bold no-underline text-white bg-olivine-500 hover:bg-olivine-400">
                  View all investments &nbsp;<FontAwesomeIcon className="ml-2 w-6 h-6 inline" icon={faAngleRight} />
                </Link>
              </footer>
            </div>
          </section>
          {/* Four */}
          <section className="bg-white mx-auto py-12 px-4 sm:px-6 lg:px-8 max-w-lg lg:max-w-7xl">
            <div className="lg:grid lg:grid-cols-2 lg:gap-4">
              <header className="col-span-2 text-center pb-4" data-aos="fade-in" data-aos-once="true" data-aos-delay="0">
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold">Meet Ram Shriram</h2>
              </header>
              <div className="col-span-1 pb-4" style={{ maxWidth: '450px', margin: '0 auto' }}>
                <StaticImage
                  src="../../static/images/RamShriram.jpg"
                  alt=""
                  loading="lazy"
                  className="border-6 border-gray-50"
                />
              </div>
              <div className="col-span-1 pb-4">
                {data.block__ram.html
                  && (
                    <div
                      dangerouslySetInnerHTML={{ __html: data.block__ram.html }}
                    />
                  )
                }
                <div className="text-center md:text-left mt-8">
                  <Link to="/ram-shriram/" aria-label="Learn more about Ram Shriram" className="inline-flex items-center justify-center margin-auto px-5 py-3 border border-transparent shadow-sm text-base font-bold no-underline text-white bg-olivine-500 hover:bg-olivine-400">
                    <FontAwesomeIcon className="mr-2 w-6 h-6 inline" icon={faInfoCircle} />Learn More
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    />
  );
};

export default IndexPage;
